@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.font-chalk {
  font-family: "Permanent Marker", cursive;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

html,
body {
  font-family: "Consolas", monospace;
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
